<template>
    <section>
        <div class="container py-15 lg:py-20 xl:py-24">
            <div class="w-full col-flex">
                <div class="w-8/10 col-flex">
                    <div class="col-flex w-full lg:max-w-9/10 xl:max-w-8/10 mx-auto text-center">
                        <global-breadcrumb
                            :breadcrumb="breadcrumb"
                            is-light
                            class="flex justify-center mb-4 lg:mb-11"
                        />
                        <h1
                            class="text-36px lg:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-semibold mt-10 xl:mt-16"
                        >
                            {{ dataDetail?.title }}
                        </h1>
                        <p class="lg:text-xl text-blacks-70 mt-6 md:text-center">
                            {{ dataDetail?.intro }}
                        </p>
                        <p class="text-blacks-70 mt-10">12 Oct 2023</p>
                    </div>
                    <div class="mt-10 lg:mt-16">
                        <div class="aspect-16/8">
                            <NuxtImg
                                :src="getThumbnail(dataDetail?.thumbnail, 960, true)"
                                width="960"
                                sizes="sm:100vw md:50vw lg:960px "
                                loading="lazy"
                                :alt="dataDetail?.title"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover"
                            />
                            <!-- <img src="/images/home/banner.png" class="w-full h-full object-cover" /> -->
                        </div>
                    </div>
                </div>
                <div class="w-7/10 col-flex mt-15 lg:mt-24">
                    <div v-html="dataDetail?.content"></div>
                    <div
                        @click="isOpen = true"
                        class="btn-arrow btn-effect-dark w-full flex items-center justify-center text-white bg-blacks-100 px-12 mt-10 lg:mt-24 h-15 cursor-pointer"
                    >
                        <p>Đăng ký tham gia</p>
                    </div>
                    <div
                        class="pt-6 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between sm:items-center w-full b-t-1 b-t-gray-[#EAEAEA] mt-24"
                    >
                        <div class="">
                            <div class="flex gap-2">
                                <div
                                    v-for="tag in 3"
                                    class="py-0.5 px-2.5 rounded-[16px] bg-#F3F3F3 text-blacks-90 text-sm leading-[20px] font-medium"
                                >
                                    Lorem
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-3 items-center">
                            <div
                                @click="handleCopyLink"
                                class="px-4 py-2.5 flex gap-2 bg-white border border-solid border-[#EAEAEA] shadow-04 cursor-pointer"
                            >
                                <icons-copy />
                                <div class="text-sm leading-[20px] text-blacks-90 font-semibold">Copy link</div>
                            </div>
                            <a
                                :href="shareLinkTwitter"
                                target="_blank"
                                class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                            >
                                <i class="i-mdi:twitter w-5 h-5 text-blacks-30"></i>
                            </a>
                            <a
                                :href="shareLinkFacebook"
                                target="_blank"
                                class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                            >
                                <i class="i-mdi:facebook w-5 h-5 text-blacks-30"></i>
                            </a>
                            <a
                                :href="shareLinkLinkedin"
                                target="_blank"
                                class="w-10 h-10 flex justify-center items-center bg-white border border-solid border-[#EAEAEA] shadow-04"
                            >
                                <i class="i-mdi:linkedin w-5 h-5 text-blacks-30"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="pt-0 lg:pt-24">
            <div class="flex flex-col lg:flex-row gap-6 lg:gap-0 justify-between items-start mb-16">
                <div class="">
                    <h3
                        class="text-blacks-100 text-2xl lg:text-4xl leading-[122.222%] tracking-[-0.72px] font-semibold mb-2 lg:mb-5"
                    >
                        Tin tức khác
                    </h3>
                    <div class="text-blacks-70 text-base lg:text-xl leading-[1.5]">
                        <v-text option_key="news_detail_related_intro" />
                    </div>
                </div>
                <nuxt-link
                    to="/tin-tuc"
                    class="btn-effect-dark w-max flex items-center gap-3 text-white bg-blacks-100 py-4 px-9 cursor-pointer"
                >
                    <p>Xem tất cả</p>
                </nuxt-link>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div v-for="(item, index) in dataDetail?.related_news" class="group">
                    <div class="h-[240px] w-full mb-6 overflow-hidden">
                        <NuxtImg
                            :src="getThumbnail(item?.thumbnail, 240, true)"
                            width="240"
                            sizes="sm:100vw md:50vw lg:240px "
                            loading="lazy"
                            :alt="item?.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                        />
                    </div>
                    <div class="text-blacks-70 text-sm font-semibold">{{ item?.category?.title }}</div>
                    <nuxt-link :to="item?.url" class="flex gap-4 my-2">
                        <div
                            class="max-w-[380px] text-blacks-100 text-base lg:text-2xl font-semibold hover:underline duration-250 line-clamp-2"
                        >
                            {{ item?.title }}
                        </div>
                        <span class="i-ic:baseline-arrow-outward w-6 h-6"></span>
                    </nuxt-link>
                    <div
                        class="text-sm lg:text-base text-blacks-90 mb-3 lg:mb-6 line-clamp-3 sm:min-h-[60px] lg:min-h-[72px]"
                    >
                        {{ item?.intro }}
                    </div>
                    <div class="text-blacks-90 text-sm">{{ formatDate(item?.date_created) }}</div>
                </div>
            </div>
        </div>
    </section>
    <Teleport v-if="isOpen" to="body">
        <PopupSignUp v-model:visible="isOpen" />
    </Teleport>
</template>

<script setup lang="ts">
// import ListArticleOther from './ListArticleOther'
// import PopupSignUp from './PopupSignUpAgency'

const route = useRoute()
const currentSlug = ref(route?.params?.slug)

const shareLinkFacebook = ref()
const shareLinkTwitter = ref()
const shareLinkLinkedin = ref()
const href = ref()
const copyToClipboard = (url: any) => {
    var sampleTextarea = document.createElement('textarea')
    document.body.appendChild(sampleTextarea)
    sampleTextarea.value = url //save main text in it
    sampleTextarea.select() //select textarea contenrs
    document.execCommand('copy')
    document.body.removeChild(sampleTextarea)
}
const copyLink = ref(false)
const handleCopyLink = () => {
    copyLink.value = true
    copyToClipboard(href.value)
    setTimeout(() => {
        copyLink.value = false
    }, 5000)
}

onMounted(() => {
    href.value = window.location.href
    shareLinkFacebook.value = `https://www.facebook.com/sharer/sharer.php?u=${href.value} `
    shareLinkTwitter.value = `https://twitter.com/share?text=&url=${href.value}`
    shareLinkLinkedin.value = `https://www.linkedin.com/sharing/share-offsite/?url=${href.value}`
})

const { getNewsDetailBySlug } = useNews()
const resDataDetail = await getNewsDetailBySlug({ slug: currentSlug.value })
const dataDetail = ref(resDataDetail?.model)
const listTags = ref(dataDetail.value?.tags.split(','))

const breadcrumb = ref([
    {
        url: '/',
        title: 'Trang chủ'
    },
    {
        url: '/tin-tuc',
        title: 'Tin tức'
    },
    {
        url: dataDetail.value?.url,
        title: dataDetail.value?.title
    }
])
</script>

<style></style>
